/* eslint-disable import/no-extraneous-dependencies */
export const MERCHANTS = {
  buyma: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-buyma.png'),
    destinationURL: 'https://www.buyma.com/?af=987',
  },
  rakuten: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-rakuten.png'),
    destinationURL: 'https://fril.jp/',
  },
  st: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-st.png'),
    destinationURL:
      'https://www.dot-st.com/?utm_source=paidy&utm_medium=CP&utm_campaign=20240815',
  },
  '2nd-street': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-2nd-street.png'),
    destinationURL: 'https://www.2ndstreet.jp/buy',
  },
  'atmos-blue': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-atmos-blue.png'),
    destinationURL: 'https://www.atmos-tokyo.com/',
  },
  'united-arrows': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-united-arrows.png'),
    destinationURL: 'https://store.united-arrows.co.jp/',
  },
  magaseek: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-magaseek.png'),
    destinationURL:
      'https://www.magaseek.com/top/index/tp_1?utm_medium=other&utm_source=other_link&utm_campaign=paidy_cpn_2408',
  },
  nb: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-nb.png'),
    destinationURL: 'https://shop.newbalance.jp/shop/',
  },
  yz: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-yz.png'),
    destinationURL: 'https://yz-store.com/',
  },
  ur: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-ur.png'),
    destinationURL: 'http://www.urban-research.jp/',
  },
  newera: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-newera.png'),
    destinationURL: 'https://www.neweracap.jp/',
  },
  runway: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-runway.png'),
    destinationURL: 'http://s.runway-ch.jp/rc_240815_paidy',
  },
  dazzy: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-dazzy.png'),
    destinationURL: 'http://dazzystore.com/Paidy-cp2408',
  },
  'ralph-lauren': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-ralph-lauren.png'),
    destinationURL: 'https://www.ralphlauren.co.jp/',
  },
  drw: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-drw.png'),
    destinationURL: 'http://d-rw.com/Paidy-cp2408',
  },
  aimerfeel: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-aimerfeel.png'),
    destinationURL: 'https://shop.aimerfeel.jp/shop/default.aspx',
  },
  'the-super-sport': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-the-super-sport.png'),
    destinationURL: 'https://www.supersports.com/ja-jp/xebio',
  },
  'dream-vision': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-dream-vision.png'),
    destinationURL: 'https://dreamvs.jp/',
  },
  '60percent': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-60percent.png'),
    destinationURL: 'https://www.sixty-percent.com/',
  },
  'taylor-made': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-taylor-made.png'),
    destinationURL:
      'https://www.taylormadegolf.jp/home?utm_source=Paidy&utm_medium=CP&utm_campaign=0815-0831',
  },
  'under-armour': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-under-armour.png'),
    destinationURL: 'https://www.underarmour.co.jp/top/CSfTop.jsp',
  },
  casio: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-casio.png'),
    destinationURL:
      'https://www.casio.com/jp/online-store/?utm_source=paidy&utm_medium=referral&utm_campaign=onlinestore_paidy-cashback-campaign_202407_jp&utm_content=%7C%7Conlinestore%7C240702%7C%7Cpaidy%E3%82%AD%E3%83%A3%E3%83%83%E3%82%B7%E3%83%A5%E3%83%90%E3%83%83%E3%82%AF%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%9A%E3%83%BC%E3%83%B3%7C%7C%7C',
  },
  'nb-outlet': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-nb-outlet.png'),
    destinationURL: 'https://outlet.newbalance.jp/shop/',
  },
  'golf-partner': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-golf-partner.png'),
    destinationURL: 'https://www.golfpartner.jp/shop/',
  },
  ships: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-ships.png'),
    destinationURL: 'https://www.shipsltd.co.jp/',
  },
  'y-aoyama': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/y-aoyama.png'),
    destinationURL: 'https://www.y-aoyama.jp/',
  },
  'atmos-pink': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/atmos-pink.png'),
    destinationURL: 'https://www.atmos-pink.com/',
  },
  'artida-oud': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-artida-oud.png'),
    destinationURL: 'http://www.artidaoud.com/',
  },
  morecontact: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-morecontact.png'),
    destinationURL: 'https://morecon.jp/',
  },
  dinos: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-dinos.png'),
    destinationURL: 'https://www.dinos.co.jp/',
  },
  green: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-green.png'),
    destinationURL:
      'https://greenfunding.jp/?utm_source=Paidy&utm_medium=GREENFUNDING&utm_campaign=Paidy-2408',
  },
  morecos: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-morecos.png'),
    destinationURL: 'https://morecos.hmv.co.jp/',
  },
  miteme: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-miteme.png'),
    destinationURL: 'https://lens-shop.miteme.co.jp/pages/lp-202408-paidy',
  },
  wlens: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-wlens.png'),
    destinationURL: 'https://www.wlens.jp/',
  },
  hoshino: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-hoshino.png'),
    destinationURL: 'https://hoshinoresorts.com/jp/',
  },
  sakura: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-sakura.png'),
    destinationURL: 'https://www.sakuratravel.jp/',
  },
  willer: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-willer.png'),
    destinationURL: 'https://travel.willer.co.jp/',
  },
  asoview: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-asoview.png'),
    destinationURL: 'https://www.asoview.com/',
  },
  apa: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-apa.png'),
    destinationURL: 'https://www.apahotel.com/',
  },
  airtrip: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/airtrip.png'),
    destinationURL:
      'https://www.airtrip.jp/?utm_source=airtrip.jp&utm_medium=web&utm_campaign=paidy_cashbackcampaign_from202408',
  },
  sorahapi: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-sorahapi.png'),
    destinationURL: 'https://www.sorahapi.jp/',
  },
  spacemarket: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-spacemarket.png'),
    destinationURL: 'https://www.spacemarket.com/',
  },
  kosokubus: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/kosokubus.png'),
    destinationURL: 'https://s.kosokubus.com/?ad=PDY',
  },
  travelist: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-travelist.png'),
    destinationURL: 'https://travelist.jp/',
  },
  busbookmark: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/busbookmark.png'),
    destinationURL: 'https://www.busbookmark.jp/',
  },
  'airtrip-hotel': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/airtrip-hotel.png'),
    destinationURL:
      'https://domhotel.airtrip.jp/?utm_so                  urce=airtrip.jp&utm_medium=web&utm_campaign=paidy_cb_campaign_from20240815',
  },
  jcation: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-jcation.png'),
    destinationURL: 'https://jcation.com/tour/',
  },
  dwang: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-dwang.png'),
    destinationURL: 'https://dwango-ticket.jp/',
  },
  dmm: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-dmm.png'),
    destinationURL: 'https://www.dmm.com/',
  },
  dlsite: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-dlsite.png'),
    destinationURL: 'https://www.dlsite.com/index.html',
  },
  'pre-bandai': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-pre-bandai.png'),
    destinationURL: 'https://p-bandai.jp/',
  },
  hololive: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-hololive.png'),
    destinationURL: 'https://shop.hololivepro.com/ ',
  },
  soundhouse: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-soundhouse.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
  },
  i7live: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-i7live.png'),
    destinationURL: 'https://jp.17.live/',
  },
  mobage: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-mobage.png'),
    destinationURL: 'https://www.mbga.jp/',
  },
  whowatch: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/whowatch.png'),
    destinationURL:
      'https://whowatch.tv/?utm_source=paidy&utm_medium=mail&utm_campaign=paidy2408',
  },
  booklive: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/booklive.png'),
    destinationURL: 'https://booklive.jp/',
  },
  hmvbooks: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/hmvbooks.png'),
    destinationURL: 'https://www.hmv.co.jp/',
  },
  rental: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/rental.png'),
    destinationURL: 'https://renta.papy.co.jp/',
  },
  mandarake: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-mandarake.png'),
    destinationURL: 'https://order.mandarake.co.jp/',
  },
  magi: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/magi.png'),
    destinationURL: 'https://magi.camp/',
  },
  torecolo: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/torecolo.png'),
    destinationURL: 'https://www.torecolo.jp/shop/default.aspx',
  },
  broccoli: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/broccoli.png'),
    destinationURL: 'https://broccolionline.jp/shop/default.aspx',
  },
  kojima: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-kojima.png'),
    destinationURL: 'https://www.kojima.net/ec/index.html',
  },
  upt: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/upt.png'),
    destinationURL: 'https://up-t.jp/',
  },
  geo: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/geo.png'),
    destinationURL: 'https://ec.geo-online.co.jp/shop/',
  },
  bicamera: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-bicamera.png'),
    destinationURL: 'https://www.biccamera.com/bc/main/',
  },
  samsung: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-samsung.png'),
    destinationURL: 'https://www.samsung.com/jp/offer/',
  },
  franc: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-franc.png'),
    destinationURL: 'https://francfranc.com/',
  },
  frontier: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/frontier.png'),
    destinationURL: 'https://www.frontier-direct.jp/',
  },
  rasik: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-rasik.png'),
    destinationURL: 'https://rasik.style/',
  },
  yamada: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-yamada.png'),
    destinationURL: 'https://www.yamada-denkiweb.com/',
  },
  tsukumo: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-tsukumo.png'),
    destinationURL: 'http://shop.tsukumo.co.jp/',
  },
  iris: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-iris.png'),
    destinationURL:
      'https://www.irisplaza.co.jp/index.php?&utm_source=paidy&utm_medium=Referral&utm_campaign=paidy_2407&utm_content=paidy_top',
  },
  tansu: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/tansu.png'),
    destinationURL: 'https://www.tansu-gen.jp/',
  },
  'bruno-online': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/bruno-online.png'),
    destinationURL: 'https://bruno-onlineshop.com/',
  },
  unico: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/unico.png'),
    destinationURL: 'https://www.unico-fan.co.jp/shop/default.aspx',
  },
  'brain-sleep': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/brain-sleep.png'),
    destinationURL: 'https://www.zzz-land.com/shop/default.aspx',
  },
  'king-jim': {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/king-jim.png'),
    destinationURL: 'https://kingjim.com/',
  },
  kurand: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/kurand.png'),
    destinationURL:
      'https://kurand.jp/?utm_source=klaviyo&utm_medium=ec__paidy&utm_campaign=22040___',
  },
  kuradashi: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/kuradashi.png'),
    destinationURL:
      'https://kuradashi.jp/?utm_source=paidy&utm_medium=site&utm_campaign=paidy2407',
  },
  enoteca: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/enoteca.png'),
    destinationURL:
      'https://www.enoteca.co.jp/?utm_source=Paidy&utm_medium=Referral&utm_campaign=cashback202408',
  },
  xserver: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/xserver.png'),
    destinationURL: 'https://www.xserver.ne.jp/',
  },
  autoway: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/logo-autoway.png'),
    destinationURL: 'https://www.autoway.jp/',
  },
  clinicfor: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/clinicfor.png'),
    destinationURL:
      'https://www.clinicfor.life/telemedicine/?utm_source=paidy&utm_medium=display&utm_campaign=paidy_202408&utm_content=online',
  },
  mogabe: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/mogabe.png'),
    destinationURL: 'https://www.mbga.jp/',
  },
  yomiuriland: {
    imgLogo: require('../../../images/merchant/logos/campaign_pages/yomiuriland.png'),
    destinationURL:
      'https://www.yomiuriland.com/yomilanclub/?_gl=1*17ftl4k*_gcl_au*Nzc0MTA4Mjg4LjE3MTk1MzEwNjQ.*_ga*OTQyMzAwMzM2LjE2OTg0OTMxNzU.*_ga_M6YBHK21TS*MTcyMzI1MjE5MC4zNTkuMC4xNzIzMjUyMTkwLjYwLjAuMA',
  },
};

export const MERCHANT_GROUPED_BY_CATEGORY = [
  {
    categoryValue: 1,
    merchants: [
      MERCHANTS.buyma,
      MERCHANTS.rakuten,
      MERCHANTS.st,
      MERCHANTS['2nd-street'],
      MERCHANTS['atmos-blue'],
      MERCHANTS['united-arrows'],
      MERCHANTS.magaseek,
      MERCHANTS.nb,
      MERCHANTS.yz,
      MERCHANTS.ur,
      MERCHANTS.newera,
      MERCHANTS.runway,
      MERCHANTS.dazzy,
      MERCHANTS['ralph-lauren'],
      MERCHANTS.drw,
      MERCHANTS.aimerfeel,
      MERCHANTS['the-super-sport'],
      MERCHANTS['dream-vision'],
      MERCHANTS['60percent'],
      MERCHANTS['taylor-made'],
      MERCHANTS['under-armour'],
      MERCHANTS.casio,
      MERCHANTS['nb-outlet'],
      MERCHANTS['golf-partner'],
      MERCHANTS.ships,
      MERCHANTS['y-aoyama'],
      MERCHANTS['atmos-pink'],
      MERCHANTS['artida-oud'],
      MERCHANTS.morecontact,
      MERCHANTS.dinos,
      MERCHANTS.green,
    ],
  },
  {
    categoryValue: 2,
    merchants: [
      MERCHANTS.morecontact,
      MERCHANTS.morecos,
      MERCHANTS.miteme,
      MERCHANTS.wlens,
      MERCHANTS.buyma,
      MERCHANTS.magaseek,
      MERCHANTS.aimerfeel,
    ],
  },
  {
    categoryValue: 3,
    merchants: [
      MERCHANTS.hoshino,
      MERCHANTS.sakura,
      MERCHANTS.willer,
      MERCHANTS.asoview,
      MERCHANTS.apa,
      MERCHANTS.airtrip,
      MERCHANTS.sorahapi,
      MERCHANTS.spacemarket,
      MERCHANTS.kosokubus,
      MERCHANTS.travelist,
      MERCHANTS.busbookmark,
      MERCHANTS['airtrip-hotel'],
      MERCHANTS.jcation,
      MERCHANTS.dwang,
      MERCHANTS.yomiuriland,
    ],
  },
  {
    categoryValue: 4,
    merchants: [
      MERCHANTS.dmm,
      MERCHANTS.dlsite,
      MERCHANTS['pre-bandai'],
      MERCHANTS.hololive,
      MERCHANTS.soundhouse,
      MERCHANTS.i7live,
      MERCHANTS.mobage,
      MERCHANTS.whowatch,
      MERCHANTS.booklive,
      MERCHANTS.hmvbooks,
      MERCHANTS.rental,
      MERCHANTS.mandarake,
      MERCHANTS.magi,
      MERCHANTS.torecolo,
      MERCHANTS.broccoli,
      MERCHANTS.kojima,
      MERCHANTS.upt,
      MERCHANTS.dwang,
    ],
  },
  {
    categoryValue: 5,
    merchants: [
      MERCHANTS.geo,
      MERCHANTS.bicamera,
      MERCHANTS.samsung,
      MERCHANTS.franc,
      MERCHANTS.frontier,
      MERCHANTS.kojima,
      MERCHANTS.rasik,
      MERCHANTS.yamada,
      MERCHANTS.dinos,
      MERCHANTS.tsukumo,
      MERCHANTS.iris,
      MERCHANTS.green,
      MERCHANTS.tansu,
      MERCHANTS['bruno-online'],
      MERCHANTS.unico,
      MERCHANTS['brain-sleep'],
      MERCHANTS['king-jim'],
      MERCHANTS.rakuten,
      MERCHANTS.soundhouse,
      MERCHANTS.st,
      MERCHANTS.casio,
    ],
  },
  {
    categoryValue: 6,
    merchants: [MERCHANTS.kurand, MERCHANTS.kuradashi, MERCHANTS.enoteca],
  },
  {
    categoryValue: 7,
    merchants: [
      MERCHANTS.xserver,
      MERCHANTS.autoway,
      MERCHANTS.clinicfor,
      MERCHANTS.upt,
      MERCHANTS.dlsite,
      MERCHANTS['pre-bandai'],
      MERCHANTS.hololive,
      MERCHANTS.asoview,
      MERCHANTS.geo,
      MERCHANTS.bicamera,
      MERCHANTS['atmos-blue'],
      MERCHANTS.samsung,
      MERCHANTS.mobage,
      MERCHANTS['united-arrows'],
      MERCHANTS.yamada,
      MERCHANTS.spacemarket,
      MERCHANTS.nb,
      MERCHANTS.tsukumo,
      MERCHANTS.booklive,
      MERCHANTS.iris,
      MERCHANTS['the-super-sport'],
      MERCHANTS.mandarake,
      MERCHANTS['taylor-made'],
      MERCHANTS['under-armour'],
      MERCHANTS['nb-outlet'],
      MERCHANTS['golf-partner'],
      MERCHANTS.ships,
      MERCHANTS.torecolo,
      MERCHANTS['bruno-online'],
      MERCHANTS.broccoli,
      MERCHANTS.jcation,
      MERCHANTS['king-jim'],
      MERCHANTS['atmos-pink'],
      MERCHANTS.yomiuriland,
    ],
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: 'ファッション' },
  { value: 2, label: 'コスメ・カラコン' },
  { value: 3, label: '航空券・ ホテル・バス・チケット' },
  { value: 4, label: '動画・ライブ配信・ホビーグッズ' },
  { value: 5, label: '家電・インテリア雑貨' },
  { value: 6, label: 'グルメ・ギフト' },
  { value: 7, label: 'その他' },
];

export const FIXED_MERCHANTS = [];

export const MERCHANTS_GROUPED_BY_FIXED_TAGS = [];

export const FIXES_TAGS = [];
